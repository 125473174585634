<template>
  <div class="register-page">
    <div class="register-panel">
      <div class="left"></div>
      <div class="right">
        <div class="title">账号注册</div>
        <el-form :model="registerForm" :rules="registerRules" ref="registerRef" class="register-form">
          <el-form-item prop="phone">
            <el-input v-model="registerForm.phone" placeholder="请输入手机号">
              <i slot="prefix" class="el-input__icon el-icon-mobile-phone"></i>
              <el-button slot="append" type="primary" v-if="sendBtnStatus" @click="sendSms">发送验证码</el-button>
            </el-input>
          </el-form-item>
          <el-form-item prop="code">
            <el-input v-model="registerForm.code" placeholder="请输入手机验证码">
              <i slot="prefix" class="el-input__icon el-icon-message"></i>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input v-model="registerForm.password" show-password placeholder="请输入密码">
              <i slot="prefix" class="el-input__icon el-icon-lock"></i>
            </el-input>
          </el-form-item>
          <el-form-item prop="repassword">
            <el-input v-model="registerForm.repassword" show-password placeholder="请重复输入密码">
              <i slot="prefix" class="el-input__icon el-icon-lock"></i>
            </el-input>
          </el-form-item>
          <el-form-item prop="company">
            <el-input v-model="registerForm.company" placeholder="请输入公司名称">
              <i slot="prefix" class="el-input__icon el-icon-trophy-1"></i>
            </el-input>
          </el-form-item>
          <el-form-item style="text-align: center;margin-top: 40px">
            <el-button type="primary" @click="submitForm('registerRef')">立即注册</el-button>
          </el-form-item>
        </el-form>
        <div class="more-info">
          <div class="item" @click="toLogin">
            去登录
          </div>
          <div class="item" @click="toReset">
            忘记密码
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Register',
  data () {
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.registerForm.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    const validatorPhone = function (rule, value, callback) {
      if (value === '') {
        callback(new Error('手机号不能为空'))
      } else if (!/^1\d{10}$/.test(value)) {
        callback(new Error('手机号格式错误'))
      } else {
        callback()
      }
    }
    return {
      registerForm: {
        phone: '',
        password: '',
        repassword: '',
        code: '',
        company: ''
      },
      sendBtnStatus: false,
      registerRules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: validatorPhone, trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 5, max: 20, message: '长度在 5 到 20 个字符', trigger: 'blur' }
        ],
        repassword: [
          { validator: validatePass2, trigger: 'blur' },
          { required: true, message: '请重新输入密码', trigger: 'blur' },
          { min: 5, max: 20, message: '长度在 5 到 20 个字符', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入手机号验证码', trigger: 'blur' },
          { min: 6, max: 6, message: '长度为 6 个字符', trigger: 'blur' }
        ],
        company: [
          { required: true, message: '请输入公司名称', trigger: 'blur' },
          { min: 5, max: 25, message: '长度在 5 到 25 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post('/register', this.registerForm)
          if (res.status === 200) {
            this.$message.success(res.msg)
          } else {
            this.$message.error(res.msg)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    toLogin () {
      this.$router.push('/login')
    },
    toReset () {
      this.$router.push('/reset-password')
    },
    async sendSms () {
      const { data: res } = await this.$http.post('/register-send-sms', { phone: this.registerForm.phone })
      if (res.status === 200) {
        this.$message.success(res.msg)
      } else {
        this.$message.error(res.msg)
      }
    }
  },
  watch: {
    'registerForm.phone': function (val, oldVal) {
      if (val !== '' && (/^1\d{10}$/.test(val))) {
        this.sendBtnStatus = true
      } else {
        this.sendBtnStatus = false
      }
    }
  }
}
</script>

<style scoped>
  .register-page{
    background-image: url('../assets/img/background.jpg');
    width: 100%;
    height: 100vh;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .register-panel{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .register-panel .left{
    width: 200px;
    height: 600px;
    background-image: url('../assets/img/left-bg.jpg');
    background-size: cover;
    border-radius: 6px 0 0 6px;
  }
  .register-panel .right{
    width: 400px;
    height: 600px;
    background-color: rgba(255,255,255,0.8);
    padding: 40px 20px;
    border-radius: 0 6px 6px 0;
  }
  .register-panel .right .title{
    font-size: 22px;
    text-align: center;
    font-weight: bold;
    opacity: 0.8;
    padding: 20px 0;
  }
  .register-panel .right .register-form{}
  .register-panel .right .more-info{
    font-size: 12px;
    text-align: center;
  }
  .register-panel .right .more-info .item{
    cursor: pointer;
    display: inline-flex;
    margin: 10px;
  }
  .register-panel .right .more-info .item:hover{
    color: #409EFF;
  }
</style>
